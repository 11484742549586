<template>
	<b-card>
		<div class="row">
			<div class="col-md-8">
				<feather-icon icon="UserIcon" size="18" class="mr-50" />
				<span class="font-weight-bold">General</span>
				<account-setting-general v-if="userData" :general-data="userData" />
			</div>

			<div class="col-md-4 border-left">
				<feather-icon icon="LockIcon" size="18" class="mr-50" />
				<span class="font-weight-bold">Change Password</span>
				<account-setting-password />
			</div>
		</div>
	</b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import { mapState } from 'vuex';
import AccountSettingGeneral from './AccountSettingGeneral.vue';
import AccountSettingPassword from './AccountSettingPassword.vue';

export default {
	components: {
		BCard,
		AccountSettingGeneral,
		AccountSettingPassword,
	},
	computed: {
		...mapState('accountSettings', ['userData', 'error']),
	},
};
</script>
